import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78851fd1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = {
  class: "col-11 col-md-8",
  style: {"margin-top":"40px"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "legal-header" }
const _hoisted_6 = { class: "legal-text" }
const _hoisted_7 = { class: "legal-header" }
const _hoisted_8 = { class: "legal-text" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "legal-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_privacy_app_de = _resolveComponent("privacy-app-de")!
  const _component_privacy_app_en = _resolveComponent("privacy-app-en")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.webPolicy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("a", {
                class: "btn policySwitchButton",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showAppPolicy && _ctx.showAppPolicy(...args)))
              }, _toDisplayString(_ctx.t('dataprotection_app_button')), 1),
              _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.t('dataprotection_header_web')), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t('dataprotection_text')), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('analytic_header')), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('analytic_text')), 1)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.webPolicy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("a", {
                class: "btn policySwitchButton",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showWebPolicy && _ctx.showWebPolicy(...args)))
              }, _toDisplayString(_ctx.t('dataprotection_web_button')), 1),
              _createElementVNode("h1", _hoisted_10, _toDisplayString(_ctx.t('dataprotection_header_app')), 1),
              (_ctx.i18n.global.locale.value.includes('de'))
                ? (_openBlock(), _createBlock(_component_privacy_app_de, { key: 0 }))
                : (_openBlock(), _createBlock(_component_privacy_app_en, { key: 1 }))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
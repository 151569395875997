

import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import PrivacyAppDe from '@/views/legal/PrivacyTexts/PrivacyAppDe.vue'
import PrivacyAppEn from '@/views/legal/PrivacyTexts/PrivacyAppEn.vue'
import i18n from '@/i18n'

export default defineComponent({
  setup() {
    // when set to false, show privacy policy of app
    const webPolicy = ref(false)

    const { t } = useI18n({
      inheritLocale: true,
      scope: 'locale'
    })

    return { t, webPolicy, i18n }
  },

  components: {
    PrivacyAppDe,
    PrivacyAppEn
  },

  methods: {
    showWebPolicy() {
      this.webPolicy = true
    },
    showAppPolicy() {
      this.webPolicy = false
    }
  }
})
